import { RootState } from "src/domains/Beacon/store";

export const selectStreamState = (state: RootState) => state.stream;

export const selectLocalMedia = (state: RootState) => state.stream?.localMedia;

export const selectVideoTrackState = (state: RootState) =>
  state.stream?.videoTrack;

export const selectAudioTrackState = (state: RootState) =>
  state.stream?.audioTrack;

export const selectSpeakerDevice = (state: RootState) =>
  state.stream?.localMedia?.speaker;

export const selectRemoteTracks = (state: RootState) =>
  state.stream?.mpRemoteTracks;

export const selectParticipantsTracks = (state: RootState) =>
  state.stream?.mpRemoteTracks.participants;

export const selectLayoutFrames = (state: RootState) =>
  state.stream.layoutFrames;

export const selectLayoutFramesSnapshot = (state: RootState) =>
  state.stream.layoutFramesSnapshot;

export const selectLayoutType = (state: RootState) => state.stream.layoutType;

export const selectLayoutTypeSnapshot = (state: RootState) =>
  state.stream.layoutTypeSnapshot;

export const selectCameras = (state: RootState) => state.stream.cameras;

// 3rd Party Integrations
export const selectIsIntegrationActive = (state: RootState) =>
  state.stream.integrationActive;

// Freeze Frame
export const selectFreezeFrameState = (state: RootState) =>
  state.stream.freezeFrame;
export const selectFreezeFrameLoading = (state: RootState) =>
  state.stream.loadingFreezeFrame;

export const selectNoiseCancelationAvailable = (state: RootState) =>
  state.stream.noiseCancellationAvailable;

export const selectNoiseCancelationEnabled = (state: RootState) =>
  state.stream.noiseCancellationEnabled;

export const selectConsoleHasExternalInputResizing = (state: RootState) =>
  state.stream.consoleHasExternalInputResizing;

export const selectPresets = (state: RootState) => state.stream.presets.options;

export const selectPresetIdSelected = (state: RootState) =>
  state.stream.presets.selectedPresetId;

// Zoom state
export const selectZoomState = (state: RootState) => state.stream.zoomState;
export const selectZoomStateSnapshot = (state: RootState) =>
  state.stream.zoomStateSnapshot;

// Sidebar
export const selectSidebarState = (state: RootState) => state.stream.sidebar;

export const selectIsSidebarLoading = (state: RootState) =>
  state.stream.loadingSidebar;

export const selectDefaultCameraPositions = (state: RootState) =>
  state.stream.defaultCameraPositions;

export const selectThirdPartyIntegrationAvailable = (state: RootState) =>
  state.stream.thirdPartyIntegrationAvailable;

// Thumbnails
export const selectToggleThumbnailsFetch = (state: RootState) =>
  state.stream.toggleThumbnailsFetch;

// Console Capabilities
export const selectConsoleCapabilities = (state: RootState) =>
  state.stream.consoleCapabilities;
