import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { EulaModal } from "src/components/legacy/Modal/EulaModal";
import {
  checkEventJoinable,
  generateMeetingToken,
  isUserInvitedToEvent,
} from "src/domains/Events/utils";
import { useDebouncedState } from "src/hooks/useDebouncedState";
import {
  getEventDetail,
  MultiPartyEvent,
  EventStatus,
} from "src/services/ApiClient/scheduler";
import { hideReCaptcha } from "src/services/ReCaptchaHelper";

import { guestLogoutAction } from "avail-web-application/Features/Authentication/AuthenticationActions";

import { StyledLink } from "avail-web-ui/components/Link";
import { LoadingSpinner } from "avail-web-ui/components/LoadingSpinner";
import { EventItem } from "avail-web-ui/domains/Events/EventItem";
import { addNotificationAction } from "avail-web-ui/dux/NotificationSlice";
import { getUserInfo } from "avail-web-ui/services/UserSessionService";

import useDeviceDetection from "../useDeviceDetection";
import styles from "./styles.module.scss";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
// SOFT-10788 import { useLDClient } from "launchdarkly-react-client-sdk";
import { logger } from "src/logging/logger";

const DEVICE_ERROR = "Sorry, your current device is not supported";
const EVENT_EXPIRED_ERROR = "Event has expired. Please contact the event host.";
const INVALID_EMAIL_ERROR =
  "Event link/data is invalid. Please try opening the email link again.";

const UNINVITED_ATTENDEE_ERROR =
  "Only invited attendees may join this event. Please contact the event host.";
const EVENT_NOT_JOINABLE_ERROR =
  "Event is scheduled for future and is not accessible now. Please try joining on the day of the event.";

const AVAIL_REMOTE_APP_URL =
  "https://itunes.apple.com/us/app/avail-remote-app/id1490348120";

// Event landing page shows a simple join button to the event the user is trying to join
// the user gets to this page by an email link telling them to join this particular event
// the URL will have the event id and join id params which we read from to know which event to let them join
export const EventLandingPage = () => {
  useEffect(hideReCaptcha, []);
  const location = useLocation();
  const { isDesktop, isIpad } = useDeviceDetection();
  const [event, setEvent] = useState<MultiPartyEvent>();
  const [error, setError] = useState("");
  const { callLink } = useFeatureFlags();
// SOFT-10788  const ldClient = useLDClient();

  const dispatch = useDispatch();

  const profile = getUserInfo();

  const loggedInUserEmail = profile!.email;

  // TODO replace with react query hopefully
  const [isLoading, setIsLoading] = useDebouncedState(true);

  useEffect(() => {
    // we need to update the LD info here because guest users after they login
    // go directly to this EventLandingPage and they dont see the HomeView route (thats another place we set this info)

    /** SOFT-10788 
    ldClient.identify(
      {
        key: profile.email,
        name: profile.firstName,
        email: profile.email,
      },
      null,
      // below callback runs after the info above is successfully saved
      // the new flags will be instantly available
      () => {
        logger().info("New user's flags available");
      }
    );
   */
    return () => {
      if (profile?.userType === "GUEST") {
        dispatch(guestLogoutAction());
      }
    };
  }, []);

  // Device detection
  useEffect(() => {
    if (isIpad) {
      window.location.href = AVAIL_REMOTE_APP_URL;
    } else if (isDesktop) {
      return;
    } else {
      setError(DEVICE_ERROR);
      dispatch(
        addNotificationAction({
          message: DEVICE_ERROR,
          type: "error",
        })
      );
    }
  }, [isDesktop, isIpad]);

  // reading event id from URL
  // they should have been set properly by the service sending out the email links to users
  const queryParams = new URLSearchParams(location.search);
  const eventId = queryParams.get("eventId");
  const joinId = profile.loginId;

  // Grab event and any other info we need for the UI from the backend
  // simple stuff like what is the event name so the user knows they are in the right place
  useEffect(() => {
    (async function () {
      try {
        if (eventId) {
          const response = await getEventDetail(eventId);

          if (response) {
            const eventInfo: MultiPartyEvent = response.content;

            // only show the expired error message for expired & cancelled events
            if (
              [EventStatus.expired, EventStatus.cancelled].includes(
                eventInfo.status
              )
            ) {
              setError(EVENT_EXPIRED_ERROR);
            } else if (!checkEventJoinable(eventInfo)) {
              setError(EVENT_NOT_JOINABLE_ERROR);
            } else if (
              !isUserInvitedToEvent(eventInfo as any, loggedInUserEmail)
            ) {
              // if the user is not invited to this event then show this error message
              setError(UNINVITED_ATTENDEE_ERROR);
            } else {
              // if the event is not expired then we want to set the event info in the state so we can actually see the join button
              setEvent(eventInfo);
            }
          }
        }
      } catch {
        setError(INVALID_EMAIL_ERROR);
      }

      // set loading to false when API returns regardless of errors
      setIsLoading(false);
    })();
  }, [eventId]);

  // if there isn't any join or event id in the URL then something bad happened so we pop up an error
  // this should be smarter in the future to make sure event is valid or just missing
  useEffect(() => {
    if (!eventId) {
      dispatch(
        addNotificationAction({
          message: INVALID_EMAIL_ERROR,
          type: "error",
        })
      );
    }
  }, [location, eventId]);

  // use the join and event id from the URL to construct the URL for the video call
  const handleJoinClick = useCallback(() => {
    if (joinId && eventId && profile) {
      const currentEventParticipant = event!.participants.find(
        (user) => user.loginId === joinId
      );

      // instead of using joinId in the token, we switched it to be the logged in user's loginId
      // if they get to this point on this page then we already know that the user is allowed into this event
      // but in the case they click on an invite that is from someone else's email, the joinId would be that other person
      // so then this user wouldn't be allowed into the event. Changing it to be login id means portalcall wont have a 401 for the joinid mismatch
      const meetingToken = generateMeetingToken(
        profile.loginId,
        eventId,
        currentEventParticipant!.role
      );

      window.open(
        `${window.location.origin}${callLink}${meetingToken}`
      );
    }
  }, [location, joinId, eventId, event, profile]);

  // TODO:fix any reference
  const eventUI = event && (
    <EventItem event={event as any} onJoinCall={handleJoinClick} />
  );

  return (
    <>
      <div className={styles.root}>
        {profile?.userType !== "GUEST" && (
          <StyledLink to="/home">
            <Grid container alignItems="center">
              <NavigateBeforeIcon />
              Return to Portal
            </Grid>
          </StyledLink>
        )}
        <div className={styles["event-container"]}>
          <Paper className={styles.paper}>
            <Grid
              className={styles.grid}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  {error ? (
                    <Typography variant="body1" className={styles.error}>
                      {error}
                    </Typography>
                  ) : (
                    <>{eventUI}</>
                  )}
                </>
              )}
            </Grid>
          </Paper>
        </div>
      </div>
      {profile?.userType === "GUEST" && <EulaModal storageType={"session"} />}
    </>
  );
};
