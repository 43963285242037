import { FeaturesConfig } from "availkit-js/dist/Models/Events/FeatureAvailabilityEvent";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { handleFourViewsLayoutThunk } from "src/domains/Beacon/store/stream/thunks/handleFourViewsLayoutThunk";
import { handleSideBarThunk } from "src/domains/Beacon/store/stream/thunks/handleSideBarThunk";
import { hasConsoleFeature } from "src/domains/Beacon/utils/presenceServer";
import { logger, LoggerLevels } from "src/logging/logger";
import { PresenceServerConsoleFeatures } from "src/services/ApiClient/console";

// Allows to know what is the current state per feature coming from Presence server,
// this is to set their state in the UI only
// ! Console Capabilities should NOT be evaluated here
export const setConsoleFeaturesThunk = createAsyncThunk<
  void,
  {
    features: FeaturesConfig | PresenceServerConsoleFeatures;
  },
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
  }
>("stream/setConsoleFeaturesThunk", ({ features }, { dispatch }) => {
  try {
    if (features === undefined) {
      return;
    }

    logger().logWithFields(
      LoggerLevels.info,
      {
        fileInfo: `setConsoleFeaturesThunk.ts`,
        feature: `store/stream/thunks`,
      },
      `Updating Console's features` + JSON.stringify(features)
    );

    // Handle bluetoothAudio
    // determine bluetooth before NoiseCancellation, if bluetooth then !noiseCancellation

    if (hasConsoleFeature("bluetoothAudio", features)) {
      const { bluetoothAudio } = features;
      // when Console uses bluetooth, we ensure Console Audio Status does not show "Built-in Microphone"
      dispatch(streamActions.setConsoleAudioIsBuiltInMic(!bluetoothAudio));
      // determines if the audio is shown as
      dispatch(streamActions.setBluetoothEnabled(bluetoothAudio));
      // if bluetoothAudio, update console.audio mute status
      if (bluetoothAudio) {
        dispatch(
          streamActions.setConsoleAudioVideo({
            audio: bluetoothAudio ? "unmuted" : "mute",
          })
        );
      }
    } else {
      logger().logWithFields(
        LoggerLevels.info,
        {
          fileInfo: `setConsoleFeaturesThunk.ts`,

          feature: `store/stream/thunks`,
        },
        "sessionDetails.features does not have property 'bluetoothAudio'"
      );
    }

    // Handle noiseCancellation
    if (hasConsoleFeature("noiseCancellation", features)) {
      dispatch(streamActions.setNoiseCancellationAvailable(true));
      dispatch(
        streamActions.setNoiseCancellationEnabled(features.noiseCancellation)
      );
    } else {
      logger().logWithFields(
        LoggerLevels.info,
        {
          fileInfo: `setConsoleFeaturesThunk.ts`,
          feature: `store/stream/thunks`,
        },
        "sessionDetails.features does not have property 'noiseCancellation'"
      );
    }

    // Handle externalInputResizing
    if (hasConsoleFeature("externalInputResizing", features)) {
      dispatch(
        streamActions.setConsoleHasExternalInputResizing(
          features?.externalInputResizing
        )
      );
    } else {
      logger().logWithFields(
        LoggerLevels.info,
        {
          fileInfo: `setConsoleFeaturesThunk.ts`,
          feature: `store/stream/thunks`,
        },
        `features does not have the property 'externalInputResizing`
      );
    }

    // Handle externalInputResizing
    if (hasConsoleFeature("sidebar", features)) {
      dispatch(
        handleSideBarThunk({
          sidebarActive: features.sidebar,
        })
      );
    } else {
      logger().logWithFields(
        LoggerLevels.info,
        {
          fileInfo: `setConsoleFeaturesThunk.ts`,
          feature: `store/stream/thunks`,
        },
        `features does not have the property 'sidebar`
      );
    }

    // Handle fourViewsLayout
    if (hasConsoleFeature("fourViewsLayout", features)) {
      dispatch(
        handleFourViewsLayoutThunk({
          fourViewsLayout: features.fourViewsLayout,
        })
      );
    } else {
      logger().logWithFields(
        LoggerLevels.info,
        {
          fileInfo: `setConsoleFeaturesThunk.ts`,
          feature: `store/stream/fourViewsLayout`,
        },
        `features does not have the property 'fourViewsLayout'`
      );
    }
  } catch (error: any) {
    logger().error(
      "There was an error while updating Console features from Presence",
      error?.message
    );
  }
});
