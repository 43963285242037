import clsx from "clsx";

import { Console } from "src/domains/Beacon/components/Console";
import { InputControls } from "src/domains/Beacon/components/InputControls/InputControls/InputControls";
import { MediaIntegration } from "src/domains/Beacon/components/MediaIntegration";
import { DRAWER_WIDTH } from "src/domains/Beacon/constants";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectIsUserHost } from "src/domains/Beacon/store/meeting/selectors";
import {
  selectFreezeFrameState,
  selectIsIntegrationActive,
  selectLayoutFrames,
  selectStreamState,
} from "src/domains/Beacon/store/stream/selectors";
import {
  LayoutFrameNames,
  LayoutTypes,
} from "src/domains/Beacon/store/stream/types";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { selectSidePanelOpen } from "src/domains/Beacon/store/ui/selectors";
import { filterFullscreenFrames } from "src/domains/Beacon/utils/layouts";
import { useScssVariable } from "src/hooks/useScssVariable";

import { Pip } from "../Pip";
import styles from "./styles.scss";

interface IProps {
  showConsoleNotPresent: boolean;
}

export const Content = ({ showConsoleNotPresent }: IProps) => {
  // Passes the drawer width to a Scss var, './styles.scss'
  const { ref } = useScssVariable<HTMLDivElement>(
    "--drawer-width",
    `${DRAWER_WIDTH}px`
  );
  const isSidePanelOpen = useAppSelector(selectSidePanelOpen);
  const isHost = useAppSelector(selectIsUserHost);
  const freezeFrameState = useAppSelector(selectFreezeFrameState);
  const isFrameFrozen = freezeFrameState.active;

  const layoutFrames = useAppSelector(selectLayoutFrames);
  const fullScreenFrames = {
    leftTopFullScreenOn: layoutFrames.leftTop.isFullScreen,
    leftBottomFullScreenOn: layoutFrames.leftBottom.isFullScreen,
    rightTopFullScreenOn: layoutFrames.rightTop.isFullScreen,
    rightBottomFullScreenOn: layoutFrames.rightBottom.isFullScreen,
  };
  const { consoleHasJoinedRoom } = useAppSelector(selectTwilioState);
  const { console: consoleState, layoutType, mpRemoteTracks } = useAppSelector(
    selectStreamState
  );
  const isConsoleVideoMuted =
    consoleHasJoinedRoom && consoleState.video === "mute";
  const remoteVideoTrackName = mpRemoteTracks?.host?.video?.name;
  const isORTTelligenceTrackParticipant =
    !isHost &&
    remoteVideoTrackName &&
    remoteVideoTrackName.indexOf("avail-integration-") !== -1;

  const isIntegrationActive = useAppSelector(selectIsIntegrationActive);

  return (
    <div
      ref={ref}
      data-test-id="content"
      className={clsx({
        [styles.root]: true,
        // only if we dont have a third party app active do we want to put this class name on
        // this classname "drawerOpens" will put a margin on the video content, pushing it into the middle
        // for 3rd party apps we want the side panel to overlay on top of the video
        [styles.drawerOpens]: isSidePanelOpen && !isIntegrationActive,
      })}
    >
      {/* This renders the console video in a `<video/>` tag */}
      <Console showConsoleNotPresent={showConsoleNotPresent} />
      {isHost && (
        <>
          <div
            className={clsx(styles[`root-left-top`], {
              // if the left full screen is on then put the selected style on
              [styles[`root-selected`]]: fullScreenFrames.leftTopFullScreenOn,
              // hide the left one if the right full screen is on
              [styles[`root-hidden`]]: filterFullscreenFrames(
                fullScreenFrames,
                "leftTopFullScreenOn"
              ),
            })}
            data-test-id={`left-top-content`}
          >
            <InputControls
              data-test-id={`left-top-input-controls`}
              isFullscreenOn={fullScreenFrames.leftTopFullScreenOn}
              side={"leftTop" as LayoutFrameNames}
              // disable this menu if frame is frozen or console has video off
              // the whole menu wont even be visible if the console isn't in the call so that check is higher up
              disabled={isFrameFrozen || isConsoleVideoMuted}
              inputSourceId={layoutFrames?.leftTop?.cameraId}
            />
          </div>
          <div
            className={clsx(styles[`root-right-top`], {
              // if the right full screen is on then put the selected style on
              [styles[`root-selected`]]: fullScreenFrames.rightTopFullScreenOn,
              // hide the right one if the left full screen is on
              [styles[`root-hidden`]]: filterFullscreenFrames(
                fullScreenFrames,
                "rightTopFullScreenOn"
              ),
            })}
            data-test-id={`right-top-content`}
          >
            <InputControls
              data-test-id={`right-top-input-controls`}
              isFullscreenOn={fullScreenFrames.rightTopFullScreenOn}
              side={"rightTop" as LayoutFrameNames}
              disabled={isFrameFrozen || isConsoleVideoMuted}
              inputSourceId={layoutFrames?.rightTop?.cameraId}
            />
          </div>

          <>
            <div
              className={clsx(styles[`root-left-bottom`], {
                [styles[`root-bottom-selected`]]:
                  fullScreenFrames.leftBottomFullScreenOn,
                // hide the left one if the right full screen is on
                [styles[`root-hidden`]]:
                  // SOFT-10451, SOFT-10922
                  // so we want to hide the bottom menus if we are in 2 view
                  // or if we are in fullscreen and this section isn't the one in fullscreen
                  layoutType === LayoutTypes.TWO_VIEW ||
                  filterFullscreenFrames(
                    fullScreenFrames,
                    "leftBottomFullScreenOn"
                  ),
              })}
              data-test-id={`left-bottom-content`}
            >
              <InputControls
                data-test-id={`left-bottom-input-controls`}
                isFullscreenOn={fullScreenFrames.leftBottomFullScreenOn}
                side={"leftBottom" as LayoutFrameNames}
                // disable this menu if frame is frozen or console has video off
                // the whole menu wont even be visible if the console isn't in the call so that check is higher up
                disabled={isFrameFrozen || isConsoleVideoMuted}
                inputSourceId={layoutFrames?.leftBottom?.cameraId}
              />
            </div>
            <div
              className={clsx(styles[`root-right-bottom`], {
                [styles[`root-bottom-selected`]]:
                  fullScreenFrames.rightBottomFullScreenOn,
                [styles[`root-hidden`]]:
                  // SOFT-10451, SOFT-10922
                  // so we want to hide the bottom menus if we are in 2 view
                  // or if we are in fullscreen and this section isn't the one in fullscreen
                  layoutType === LayoutTypes.TWO_VIEW ||
                  filterFullscreenFrames(
                    fullScreenFrames,
                    "rightBottomFullScreenOn"
                  ),
              })}
              data-test-id={`right-bottom-content`}
            >
              <InputControls
                data-test-id={`right-bottom-input-controls`}
                isFullscreenOn={fullScreenFrames.rightBottomFullScreenOn}
                side={"rightBottom" as LayoutFrameNames}
                disabled={isFrameFrozen || isConsoleVideoMuted}
                inputSourceId={layoutFrames?.rightBottom?.cameraId}
              />
            </div>
          </>
        </>
      )}
      {isORTTelligenceTrackParticipant && (
        <MediaIntegration data-test-id="media-integration" />
      )}
      <Pip data-test-id="pip-camera" />
    </div>
  );
};
