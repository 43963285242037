import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";

export interface StreamState {
  integrationActive: boolean; // Let know if a 3rd party integration is being shown
  cameras: (AVKCamera | AVKExternalInput)[];
  console: CallParticipantAudioVideoMuteStatus;
  freezeFrame: FreezeFrame;
  layoutFrames: LayoutFrames;
  layoutFramesSnapshot: LayoutFrames; // layouts to reset to when stopping 3rd party integrations or when closing fullscreen
  layoutTypeSnapshot: LayoutType; // when closing fullscreen we want to revert to the snapshot of the view the user was at previously, so we need to save which layout they were in (2 view or 4 view, etc)
  layoutType: LayoutType;
  pip: "hide" | "show";
  presets: Presets;
  self: CallParticipantAudioVideoMuteStatus;
  sidebar: "active" | "inactive";

  // localMedia has all the available media devices and also the selected
  // camera, microphone and speaker to be used in the call
  localMedia: LocalMediaDeviceEnumeration | null;
  // LocalVideoTrack and LocalAudioTrack refer to the Twilio's created tracks
  // when publishing them to the Twilio's room when "joining" the call
  // and dispatching the 'publishVideoTrackThunk' and 'publishAudioTrackThunk'
  videoTrack: LocalVideoTrack;
  audioTrack: LocalAudioTrack;
  screenShareTrack: LocalVideoTrack;

  // remote media
  remoteTracks: LocalOrRemoteMediaTrack[];
  mpRemoteTracks: MPRemoteTracks;
  isVideoTrackPublished: boolean; // Host video mute/unmute is disabled until videoTrack is published to Twilio only
  videoTrackPublishLoading: boolean; // Loading state until video track is published to Twilio
  isAudioTrackPublished: boolean; // Participant/Host audio mute/unmute is disabled until audioTrack is published to Twilio only
  audioTrackPublishLoading: boolean; // Loading state until audio track is published to Twilio

  // properties for async handling (pending/loading, success, failure/error)
  // On thunks created using `createAsyncThunk` helper, actions are dispatched automatically for:
  //   - pending/loading state for when the thunk initiates (before the the async process aka an api call happens)
  //   - success state for when the api call resolves (there are handlers for these in `extraReducers` in the main slice file)
  //      - when the API call resolves, `loading` can then be set to false
  //   - failure/error state is the API call fails or there's an error. If an error is thrown in the thunk, a failure/error action is dispatched
  error: { message: string } | null;

  // when we use extra builders in the thunk we still need individual loading/error states so we can grab them once they are set
  localMediaLoading: boolean;
  localMediaError: { message: string } | null;
  loadingSidebar: boolean;
  loadingFreezeFrame: boolean;

  // console's available features returned from console or presence server
  bluetoothEnabled: boolean;
  noiseCancellationAvailable: boolean;
  noiseCancellationEnabled: boolean;
  consoleHasExternalInputResizing: boolean;

  consoleAudioIsBuiltInMic: boolean;
  // current zoom state of the selected sources
  zoomState: ZoomFrames;
  // Must store zoom state before TPI to reset PTZ and zoom level
  zoomStateSnapshot: ZoomFrames;
  defaultCameraPositions: DefaultCameraPosition;
  // call has TPI enabled
  thirdPartyIntegrationAvailable: boolean;

  // Work as a toggle in order to fire react-query and fetch for the new thumbnails in Presence
  toggleThumbnailsFetch: boolean;

  // Will have all the available Console capabilities list with a value "true" or "false" on each of them
  consoleCapabilities: ConsoleCapabilities;
}

// TODO: add audio mediaDeviceInfo property
export interface LocalMediaDeviceEnumeration {
  camera: MediaDeviceInfo | null;
  microphone: MediaDeviceInfo | null;
  speaker: MediaDeviceInfo | null;
  mediaDevices: MediaDevicesInfo;
}

export interface MediaDevicesInfo {
  audioInputs: MediaDeviceInfo[];
  audioOutputs: MediaDeviceInfo[];
  videoInputs: MediaDeviceInfo[];
}

export type LocalOrRemoteMediaTrack =
  | LocalAudioTrack
  | LocalVideoTrack
  | RemoteAudioTrack
  | RemoteVideoTrack;

export interface ParticipantAudioTrackPayload {
  loginId: string;
  audioTrack: RemoteAudioTrack;
}

// TODO: Participant videoTrack payload in a future?

export interface ParticipantTracks {
  [participantLoginId: string]: [RemoteVideoTrack, RemoteAudioTrack];
}
export interface TrackObject {
  audio: RemoteAudioTrack;
  video: RemoteVideoTrack;
}

export interface MPRemoteTracks {
  console: TrackObject;
  host: TrackObject;
  participants: ParticipantTracks;
}

export interface CallParticipantAudioVideoMuteStatus {
  audio?: "mute" | "unmute";
  video?: "mute" | "unmute";
}

// allowed names for LayoutFrames
export type LayoutFrameNamesLeftBottom = "leftBottom";
export type LayoutFrameNamesRightBottom = "rightBottom";
export type LayoutFrameNamesLeftTop = "leftTop";
export type LayoutFrameNamesRightTop = "rightTop";
export type LayoutFrameNames =
  | LayoutFrameNamesLeftBottom
  | LayoutFrameNamesRightBottom
  | LayoutFrameNamesLeftTop
  | LayoutFrameNamesRightTop;

// Info of the camera for the layout frame ("leftTop" | "leftBottom" | "rightTop" | "rightBottom")
export interface LayoutFrameInfo {
  cameraId: string;
  cameraLabel: string;
  isFullScreen: boolean;
  integrationName?: string;
  integrationLink?: string;
}

// object with the keys "left" | "right" with the info of the camera to be
// used on each frame
export type LayoutFrames = {
  [frameName in LayoutFrameNames]: LayoutFrameInfo;
};

export type LayoutTypeTwoView = "TWO_VIEW";
export type LayoutTypeFourView = "FOUR_VIEW";
export type LayoutTypeFullscreen = "FULLSCREEN";
export type LayoutType =
  | LayoutTypeFullscreen
  | LayoutTypeTwoView
  | LayoutTypeFourView;

export enum LayoutTypes {
  TWO_VIEW = "TWO_VIEW",
  FOUR_VIEW = "FOUR_VIEW",
  FULLSCREEN = "FULLSCREEN",
}

export interface FreezeFrame {
  // For now, fullscreen is the only one that works for either P2P and MP calls
  type: "fullscreen";
  active: boolean;
  transferId: string;
  imageData: string;
}

// TODO: This ZoomSettings is used in PortalCall?
export interface ZoomSettings {
  start: number;
  min: number;
  max: number;
  step: number;
}

export interface ZoomSlider {
  cameraId: string;
  value: number;
  settings: ZoomSettings; // TODO: Check if it's gonna be used
}

// Has all the zoom values of the selected preset for both "left" and "right" frames
export type ZoomFrames = {
  // frameNames can only be either "left" or "right"
  [frameName in LayoutFrameNames]: ZoomSlider;
};
export interface Preset {
  id: string; // unique identifier per preset
  name: string; // preset's name
  layoutFrames: LayoutFrames; // selected layout
  zoomState?: ZoomFrames;
  cameras?: (AVKCamera | AVKExternalInput)[];
  // layout type for preset
  presetLayout: string;
}

export interface Presets {
  // id of the selected preset from the list
  selectedPresetId?: string;
  // Array of all the presets created by the user
  options: Preset[];
}

export interface IHandleFreezeFrame {
  transferId: string;
  imageData: string;
}

// object with the keys "front" | "overhead" with the info of the camera position
export interface DefaultCameraPosition {
  [camera: string]: CameraPosition;
}
export interface CameraPosition {
  x: number;
  y: number;
}

export interface LayoutIntegrationLink {
  frameSelected: LayoutFrameNames;
  integrationName: string;
  integrationLink: string;
}

// All existing console capabilities list
export enum ConsoleCapabilitiesKeys {
  FOUR_VIEWS_LAYOUT = "fourViewsLayout",
  SIDEBAR = "sidebar",
}

export type ConsoleCapabilities = {
  [key in ConsoleCapabilitiesKeys]?: boolean;
};
