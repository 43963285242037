import { RootState } from "src/domains/Beacon/store";
import { AppDispatch } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { handleLayoutChangeThunk } from "src/domains/Beacon/store/stream/thunks/handleLayoutChangeThunk";
import { logger } from "src/logging/logger";

// Restores the layouts based on snapshot state, this is it since stopping screen sharing
// the snapshot init is outdated and if user has manipulated the opposite camera while using TPI,
// the cameras won't persist

export const resetLayoutsSnapshotThunk = () => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  logger().info("Restoring layouts from snapshot...");

  try {
    const {
      stream: { layoutFramesSnapshot, layoutTypeSnapshot },
    } = getState();
    logger().info(
      "Restoring layouts from snapshot, layout frame snaphots: ",
      layoutFramesSnapshot,
      "layout type snapshot: ",
      layoutTypeSnapshot
    );

    // Reset the layouts list selection based on the latest snapshot
    // SOFT-10451, SOFT-10922 moving these above handleLayoutChangeThunk because
    // depending on if we are in 2 view or 4 view, handleLayoutChangeThunk will trim the extra views before sending it to webcall
    dispatch(streamActions.setLayoutFrames(layoutFramesSnapshot));
    dispatch(streamActions.setLayoutType(layoutTypeSnapshot));

    // Must restore the cameras from snapshot
    dispatch(
      handleLayoutChangeThunk({
        externalInputsImageResizing: true,
        newLayoutFrames: layoutFramesSnapshot,
      })
    );
  } catch (error: any) {
    logger().error(
      `Error while restoring layouts from snapshot.`,
      error?.message
    );
  }
};
