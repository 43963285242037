import "semantic-ui-css/semantic.min.css";

import { ConnectedRouter } from "connected-react-router";
// SOFT-10788 import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";

// import { FEATURE_FLAG_ID } from "src/constants";
import "../avail-web-application/src/i18n";
import "../avail-web-application/src/index.scss";
import reportWebVitals from "../avail-web-ui/src/reportWebVitals";
import App from "./App";
import store, { history } from "./reducer";

// eslint-disable-next-line require-await
(async () => {
  // SOFT-10788
  // const LDProvider = await asyncWithLDProvider({
  //   clientSideID: FEATURE_FLAG_ID,
  //   user: {
  //     anonymous: true, // This is set before the user is logged in, since we don't have the user's information yet
  //   },
  //   // flags: {} // Only subscribe to specific flags (could be useful for testing)
  //   options: {
  //     /* ... */
  //   },
  // });
  // Important note on Launch Darkly: the React SDK automatically changes
  // all flag keys to camel case. A flag with key dev-flag-test is
  // accessible as flags.devFlagTest.

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
  });

  ReactDOM.render(
    // SOFT-10788    <LDProvider>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {/* // ! FIX:  does not have children prop */}
        {/* @ts-ignore */}
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </QueryClientProvider>,
    // SOFT-10788   </LDProvider>,
    document.getElementById("root")
  );
})();

// FOR CYPRESS
if ((window as any).Cypress) {
  (window as any).store = store;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
