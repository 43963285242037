// SOFT-10788 import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { Provider, useDispatch } from "react-redux";

import { Indicator } from "src/components/Indicator/Indicator";
import { NotificationSystem } from "src/components/NotificationSystem";
import { BottomBar } from "src/domains/Beacon/components/BottomBar";
import { Content } from "src/domains/Beacon/components/Content";
import { LastParticipantRemainingModal } from "src/domains/Beacon/components/LastParticipantRemainingModal";
import { LeaveCallHandler } from "src/domains/Beacon/components/LeaveCallHandler";
import { MinimumBrowserSizeModal } from "src/domains/Beacon/components/MinimumBrowserSizeModal";
import { NetworkConnectionLostModal } from "src/domains/Beacon/components/NetworkConnectionLostModal";
import { Palette } from "src/domains/Beacon/components/Palette/Palette";
import { PreCallHandler } from "src/domains/Beacon/components/PreCallHandler/PreCallHandler";
import { PreCallPermissionMessages } from "src/domains/Beacon/components/PreCallHandler/PreCallPermissionsMessages/PreCallPermissionsMessages";
import { ScreenShareModal } from "src/domains/Beacon/components/ScreenShareModal";
import { SidePanel } from "src/domains/Beacon/components/SidePanel";
import { TopBar } from "src/domains/Beacon/components/TopBar";
import { TwilioSubscriptions } from "src/domains/Beacon/components/TwilioSubscriptions";
import { useGetPresenceCallSession } from "src/domains/Beacon/hooks/PresenceCallSession";
import { useGetMeetingToken } from "src/domains/Beacon/hooks/useGetMeetingToken";
import { useInitGlobalStyles } from "src/domains/Beacon/hooks/useInitGlobalStyles";
import { useJoinCallWithoutPreCall } from "src/domains/Beacon/hooks/useJoinCallWithoutPreCall";
import { useJoinPubnubChannel } from "src/domains/Beacon/hooks/useJoinPubnubChannel";
import { useMediaDevicesReady } from "src/domains/Beacon/hooks/useMediaDevicesReady";
import { useStartRefreshTokensTimers } from "src/domains/Beacon/hooks/useStartRefreshTokensTimers";
import { store, useAppSelector } from "src/domains/Beacon/store";
import { meetingActions } from "src/domains/Beacon/store/meeting";
import {
  selectIsUserHost,
  selectMeetingState,
} from "src/domains/Beacon/store/meeting/selectors";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { selectStreamState } from "src/domains/Beacon/store/stream/selectors";
import { updateLocalMediaThunk } from "src/domains/Beacon/store/stream/thunks/updateLocalMediaThunk";
import { selectTelestrationState } from "src/domains/Beacon/store/telestration/selectors";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import {
  selectIndicatorState,
  selectIsUiHidden,
  selectSidePanelOpen,
} from "src/domains/Beacon/store/ui/selectors";
import { AvailKitSubscriptions } from "src/domains/Beacon/subscriptions";
import { useEventListener } from "src/hooks/useEventListener";
import {
  /* LDFlagSet,*/
  useFeatureFlags,
} from "src/hooks/useFeatureFlag";

// import { logger } from "src/logging/logger";
// import { history } from "src/reducer";
// import { MultiPartyEvent } from "src/services/ApiClient/scheduler";
// import UserSessionService from "src/services/UserSessionService";

// Temporary component to reference for a separate redux-state.
// I didn't want to merge the beacon redux-state with what we have in
// the rest of the app just to avoid any possible clashes.
export const BeaconWithState = () => {
  // Wrapper for redux store.
  return (
    <Provider store={store}>
      <Beacon />
    </Provider>
  );
};

const Beacon = () => {
  const isSidePanelOpen = useAppSelector(selectSidePanelOpen);
  const isUiHidden = useAppSelector(selectIsUiHidden);
  const indicatorState = useAppSelector(selectIndicatorState);
  const { isDrawModeOn } = useAppSelector(selectTelestrationState);
  const { callDetails, mode } = useAppSelector(selectMeetingState);
  const { getCredentialsError } = useAppSelector(selectTwilioState);
  const { localMediaError } = useAppSelector(selectStreamState);

  // SOFT-10788 const ldClient = useLDClient();
  // const loggedInUser = UserSessionService.getCachedUserInfo();

  const { tungstenExternalLa, offerDeviceSelection } = useFeatureFlags();

  useEffect(() => {
    // Must wait until these values aren't null, otherwise, LD identify is fired multiple times,
    // and at some point the custom attributes aren't ready
    if (mode) {
      // if we are in MP call mode and we DONT have call details yet then just return
      // when callDetails is updated outside then this useEffect will run again and
      // at that point we can skip return and run the LD identify function below
      if (mode === CallModes.MP && !callDetails) {
        return;
      }

      // This is why we are waiting for call details, we must check the facility name if enabled for ORT, only in MP calls
      // const custom =
      //   mode === CallModes.MP
      //     ? {
      //         "Facility Name": (callDetails as MultiPartyEvent)?.hospitalName,
      //       }
      //     : null;

      // we need to check if the user is allowed to be on this page, we can't just hide the route
      // because the routes don't rerender when the launch darkly API returns for some reason
      // this only exists to prevent users from going directly to the /beacon URL, all join buttons have the feature flag check already
      // /** SOFT-10788
      // ldClient.identify(
      //   {
      //     key: loggedInUser.email,
      //     name: loggedInUser.firstName,
      //     email: loggedInUser.email,
      //     custom,
      //   },
      //   null,
      //   // below callback runs after the info above is successfully saved
      //   // the new flags will be instantly available
      //   (error, flags: LDFlagSet) => {
      //     if (error) {
      //       logger().error(
      //         "An error occurred while fetching user's flags with FacilityName",
      //         error?.message
      //       );
      //     }

      //     // once we have the flags, check if this user has the Tungsten flag to true
      //     // if they dont then send them to the not-found page
      //     // TODO we need to figure out a more general way to do this
      //     /** * SOFT-10788
      //     if (!flags.tungsten) {
      //       history.push("/not-found");
      //     }

      //     // ! Our local state to know what the latest LD-flags values are, there's a
      //     // ! topic in LD called `variations` were we must wait until we have the latest value for each flag
      //     // ! otherwise, we'll have intermittent values causing weird bugs
      //     dispatch(meetingActions.setLaunchDarklyFetchDone(true));
      //   }
      // ); **/
      dispatch(meetingActions.setLaunchDarklyFetchDone(true));
    }
  }, [mode, callDetails]);

  const dispatch = useDispatch();

  // The next hooks' calls follow a process to bootstrap all of the initial
  // data for twilio and media. The general process follows the following order:
  // 1. Get the meetingToken from the url query params on startup
  // 2. Get Local Media data (audio/video inputs for local user)
  // 3. After obtaining the meetingToken data, we must call for the twilio token (this is needed for twilio-video lib to init)
  // 4. After successfully getting the twilio token, connect to twilio-video (which will give us the media tracks necessary to mount onto DOM)
  // 5. After initializing Twilio, listen for events being fired on the Twilio room. Room.on("callbacks")
  // 6. Join the correct pubnub channels (session channel) via AvailKit to start listening for event in the call
  // 7. TBA

  // Will only work if user has `offerDeviceSelection` "true" in order to fetch callDetails while user is in PreCall screen
  useGetMeetingToken();

  // Otherwise, must have to follow a step-by-step process for: meetingToken, get callDetails, mediaDevices permissions,
  // set default mediaDevices for audio and video, and finally join the Twilio room
  useJoinCallWithoutPreCall();

  // Starts refresh timers for Avail access token and Twilio access token
  useStartRefreshTokensTimers();
  // Step 4: Once the session id is set and user has joined Twilio room, then
  // that session id will be used to join the pubnub channel
  useJoinPubnubChannel();
  // Gets the session details of the call stored in the Presence Server
  // to load once at the beginning of the call:
  // 1. Sidebar status
  // 2. Freeze Frames
  // 3. Noise Cancellation status
  // 4. External Input Resizing status
  useGetPresenceCallSession();
  const { mediaDevicesReady } = useMediaDevicesReady();

  // TODO useGetPresenceServerState - Multiparty

  // Detects when a new media device is either removed or plugged
  useEventListener(
    "devicechange",
    (e) => {
      // using ref to ensure the current variable value is received, listeners cannot read state
      // if (callStepRef.current === CallSteps.PRE_CALL) {
      /**
       * Currently restricting this listener to PreCall.
       * When restriction removed (enabling listener throughout call) )issues
       * arise in persisting User speaker selection into the call.
       */
      e.preventDefault();
      // Should only update devices during the PreCall
      // TODO: this would change when changing devices on the Settings SidePanel
      dispatch(updateLocalMediaThunk());
      // }
    },
    navigator.mediaDevices
  );

  const isHost = useAppSelector(selectIsUserHost);

  // initialize some global styles for Beacon
  useInitGlobalStyles();

  return (
    <>
      {/* Notification system lives outside of the regular html structure, when notifications are dispatched they appear on top of the whole app */}
      <NotificationSystem />
      {/* Indicator system that wraps the entire app with a state message */}
      <Indicator {...indicatorState} />
      <div style={{ display: "flex" }}>
        <TopBar isHost={isHost} sidePanelOpen={isSidePanelOpen} />
        <Content
          showConsoleNotPresent={
            !(localMediaError && getCredentialsError?.message) &&
            mediaDevicesReady
          }
        />
        <BottomBar
          sidePanelOpen={isSidePanelOpen}
          hidden={isUiHidden}
          isHost={isHost}
        />

        {isHost ? <Palette hidden={!isDrawModeOn} /> : null}
      </div>
      <>
        {/* Modals or Drawers that will are outside of the regular html structure that open/close when different actions happen */}
        {/* use PreCallHandler if offerDeviceSelection is true, otherwise if its false then we just want to check for errors in PreCallPermissionMessages */}
        {offerDeviceSelection ? (
          <PreCallHandler />
        ) : (
          <PreCallPermissionMessages
            localMediaError={localMediaError?.message}
            apiErrors={getCredentialsError?.message}
          />
        )}
        <SidePanel />
        <LeaveCallHandler />
        {tungstenExternalLa && <MinimumBrowserSizeModal />}
        {tungstenExternalLa && <LastParticipantRemainingModal />}
        {tungstenExternalLa && <NetworkConnectionLostModal />}
        <ScreenShareModal />
      </>
      <>
        {/* Subscription components which run hooks that handles listening for availkit / twilio events */}
        <AvailKitSubscriptions />
        {/* TODO: CrossCheck if <audio> and <video> should be together or be separated in <div>s */}
        <TwilioSubscriptions />
      </>
    </>
  );
};
