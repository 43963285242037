import { useDispatch } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

import { IconButton } from "src/components/Button/IconButton/IconButton";
import { Tooltip } from "src/components/Tooltip";
import { PTZToolTips } from "src/constants/tooltips";
import { InputControlsDirectionalPad } from "src/domains/Beacon/components/InputControls/InputControlsDirectionalPad";
import { InputControlsSlider } from "src/domains/Beacon/components/InputControls/InputControlsSlider";
import { useAppSelector } from "src/domains/Beacon/store";
import {
  selectIsIntegrationActive,
  selectLayoutFrames,
  selectLayoutType,
} from "src/domains/Beacon/store/stream/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { handleLayoutChangeThunk } from "src/domains/Beacon/store/stream/thunks";
import { resetLayoutsSnapshotThunk } from "src/domains/Beacon/store/stream/thunks/resetLayoutsSnapshotThunk";
import {
  LayoutFrameNames,
  LayoutFrames,
} from "src/domains/Beacon/store/stream/types";
import { LayoutTypes } from "src/domains/Beacon/store/stream/types";
import { uiActions } from "src/domains/Beacon/store/ui";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { logger } from "src/logging/logger";

import styles from "./styles.scss";

export interface InputControlsMenuProps {
  side: LayoutFrameNames;
  toggleKebab: () => void;
  isFullscreenOn: boolean;
  "data-test-id"?: string;
}

export const InputControlsMenu = ({
  "data-test-id": dataTestId,
  isFullscreenOn = false,
  side,
  toggleKebab,
}: InputControlsMenuProps) => {
  const dispatch = useDispatch();
  const layoutFrames = useAppSelector(selectLayoutFrames);
  const layoutType = useAppSelector(selectLayoutType);
  const isIntegrationActive = useAppSelector(selectIsIntegrationActive);
  const { externalInputsImageResizing, tungstenExternalLa } = useFeatureFlags();

  const expandFullscreen = (): void => {
    // SOFT-10451, SOFT-10922
    // hold previous layout frames in snapshot slice of redux so when they close fullscreen we can reset to these layouts
    dispatch(streamActions.setLayoutFramesSnapshot(layoutFrames));
    dispatch(streamActions.setLayoutTypeSnapshot(layoutType));

    logger().info(
      "User clicked on fullscreen, saving layouts to snapshot: ",
      layoutFrames,
      "layout type snapshot: ",
      layoutType
    );

    const newLayoutFrames = {
      ...layoutFrames,
      [side]: {
        ...layoutFrames[side],
        isFullScreen: !layoutFrames[side].isFullScreen,
      },
    } as LayoutFrames;

    if (!tungstenExternalLa) {
      /**
       * if tungstenExternalLa === true
       *    we have the LayoutsPresets Button and can skip closing the SidePanel
       * else tungstenExternalLa === false
       *     closing SidePanel is only needed when we are using separate Layouts and Presets
       */
      dispatch(uiActions.setSidePanelOpen(false));
    }
    // need to set layout frames here
    dispatch(streamActions.setLayoutFrames(newLayoutFrames));
    dispatch(streamActions.setLayoutType(LayoutTypes.FULLSCREEN));

    // passing in empty layouts so the internal code can decide which layouts to use depending on if any Integrations are active
    // passing in empty layouts so the internal code can decide which layouts to use depending on if any Integrations are active
    dispatch(
      handleLayoutChangeThunk({
        externalInputsImageResizing,
      })
    );
  };

  const closeFullscreen = (): void => {
    dispatch(resetLayoutsSnapshotThunk());
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <Tooltip title={PTZToolTips.close} color="black" placement="left">
          <IconButton
            aria-label="callcontrols-close"
            background="dark"
            color="white"
            className={styles.icon}
            onClick={toggleKebab}
            data-test-id={`${dataTestId}-callcontrols-kebab-close`}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className={styles.iconWrapper}>
        <Tooltip
          title={PTZToolTips[isFullscreenOn ? "collapse" : "expand"]}
          color="black"
          placement="left"
        >
          <IconButton
            aria-label="callcontrols-fullscreen"
            background="dark"
            color="white"
            className={styles.icon}
            // if 3rd party integration is active, disable fullscreen since it will be cut off by integration overlay
            disabled={isIntegrationActive}
            onClick={() => {
              // if fullscreen is on then we want to able to close it
              // if its not on then we want to be able to open it
              if (isFullscreenOn) {
                closeFullscreen();
              } else {
                expandFullscreen();
              }
              // but regardless of above, we want to close the kebab menu everytime we open/close fullscreen
              toggleKebab();

              // Must reset the selected preset, info will not match afterwards
              dispatch(streamActions.setPresetSelected(null));
            }}
            data-test-id={`${dataTestId}-callcontrols-fullscreen`}
          >
            {isFullscreenOn ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
          </IconButton>
        </Tooltip>
      </div>
      <InputControlsSlider
        data-test-id={`${dataTestId}-callcontrols-zoom`}
        side={side}
      />
      <InputControlsDirectionalPad
        data-test-id={`${dataTestId}-callcontrols-pad`}
        side={side}
      />
    </div>
  );
};
