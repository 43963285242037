import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { LoggerLevels, logger } from "src/logging/logger";

// Handles the unfreeze frame for the created canvas, for now, it's only
// working for fullscreen freeze frame due that PortalCall didn't implement it
export const handleFourViewsLayoutThunk = ({
  fourViewsLayout,
}: {
  fourViewsLayout: boolean;
}) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const loggerFieldInfo = {
    fileInfo: `handleFourViewsLayout.ts`,
    feature: `store/stream/fourViewsLayout`,
  };

  try {
    const { consoleCapabilities } = getState().stream;

    logger().logWithFields(
      LoggerLevels.info,
      loggerFieldInfo,
      `handling fourViewsLayout state: ${fourViewsLayout}`
    );

    const newCaps = {
      ...consoleCapabilities,
      fourViewsLayout,
    };

    // Dispatch sidebar status to state so we can update the UI
    await dispatch(streamActions.setConsoleCapabilities(newCaps));
  } catch (error: any) {
    logger().logWithFields(
      LoggerLevels.error,
      loggerFieldInfo,
      `Error while handling SideBar status,
        ${error?.message}`
    );
    throw error;
  }
};
